import { Box, Grid, Typography } from '@mui/material';
import { graphql } from 'gatsby';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Encounters from '../../components/pages/bounties/Encounters';
import Header from '../../components/pages/bounties/Header';
import TimeLine from '../../components/pages/bounties/TimeLine';
import Layout from '../../components/Layout';
import Link from '../../components/navigation/Link';
import SeoHeader from '../../components/SeoHeader';

const layout = {
  SeoProps: {
    title: 'Fractal Bounties',
    description:
      'Play together with your friends and compete for awesome prizes in the bounties event!',
  },
  ContainerProps: {
    paper: true,
  },
};

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: 18,
  },
  headlien: {
    marginTop: theme.spacing(2),
  },
}));

function BountiesPage({ data: { cmFull, t4Full }, location }) {
  const { classes } = useStyles();

  const cmFullImage = cmFull.edges[0]?.node.gatsbyImageData;
  const t4FullImage = t4Full.edges[0]?.node.gatsbyImageData;

  return (
    <Layout ContainerProps={layout.ContainerProps} location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <Header page="/bounties" />

      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={9}>
          <Box p={2}>
            <Typography variant="body1" paragraph>
              In partnership with Guild MM we are announcing bounties on fractal
              bosses! As a fellow fractal player, this is your call to hunt down
              the bounties and collect the reward.
            </Typography>
            <Typography variant="body1" paragraph>
              Bounties are a new type of fractal competition, taking place as
              occasional standalone events. They require a less rigid time
              allocation from participants, than a classic (live) tournament
              format would. At the start of each event, both a T4 Fractal and a
              Challenge Mode boss will be drawn (based upon the{' '}
              <Link to="/guides/new-player-guide/">instabilities</Link> for the
              event duration) and teams will compete to get the fastest clear /
              kill times on both encounters. After the announcement of the two
              bounties, teams will have two weeks to practice and record their
              best runs following the{' '}
              <Link to="/bounties/rules">Fractal Bounties Ruleset</Link>. Once
              the two-week event is up, teams will have two days to submit their
              best runs via the{' '}
              <Link to="/bounties/submission">submission page</Link> following
              the submission information found below.
            </Typography>

            <Typography variant="body1" paragraph>
              Teams other than the first three will also be rewarded, allowing
              everyone to have a shot at a price.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <iframe
            title="Discord embed"
            src="https://discord.com/widget?id=901023522208509952&theme=dark"
            width="100%"
            height="100%"
            style={{ minHeight: 300 }}
            allowtransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          />
        </Grid>
      </Grid>

      <Typography variant="h4" className={classes.headline}>
        New Year Bounty 2022
      </Typography>

      <Encounters
        t4={{ name: 'Swampland', picture: t4FullImage }}
        cm={{ name: 'Siax', picture: cmFullImage }}
      />

      <TimeLine />
    </Layout>
  );
}

export default BountiesPage;
export const query = graphql`
  {
    cmFull: allImageSharp(
      filter: {
        original: {
          src: { eq: "/static/siax-5ba831fe6ce948791c56237b870858d2.jpg" }
        }
      }
    ) {
      edges {
        node {
          gatsbyImageData(
            width: 1920
            height: 1080
            transformOptions: { cropFocus: CENTER }
          )
          original {
            src
          }
        }
      }
    }

    t4Full: allImageSharp(
      filter: {
        original: {
          src: {
            eq: "/static/bloomhunger_spirit_onslaught-bbefa3d49825a6a8690f7a7c9cb05240.jpg"
          }
        }
      }
    ) {
      edges {
        node {
          gatsbyImageData(
            width: 1920
            height: 1080
            transformOptions: { cropFocus: CENTER }
          )
          original {
            src
          }
        }
      }
    }
  }
`;
