import { Grid, Typography } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  image: {
    borderRadius: 10,
    filter: 'brightness(80%)',
  },
  placeholder: {
    borderRadius: 10,
    filter: 'brightness(50%) blur(4px)',
  },
  content: {
    position: 'relative',
    textAlign: 'center',
  },
  root: {
    margin: theme.spacing(2),
  },
  encounterHeadline: {
    color: theme.palette.primary.main,
    top: '90%',
    left: ' 50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
}))

function Encounters({
  cm: { picture: cmPicture, name: cmName },
  t4: { picture: t4Picture, name: t4Name },
}) {
  const { classes } = useStyles()
  const [state, setState] = React.useState({
    cm: { blur: false },
    t4: { blur: false },
  })

  const data = useStaticQuery(graphql`
    query EncounterPlaceholderImage {
      placeholder: allImageSharp(
        filter: {
          original: {
            src: {
              eq: "/static/instabilities-dae1eb3fab2b1621c3045f9fed0eb268.jpg"
            }
          }
        }
      ) {
        edges {
          node {
            gatsbyImageData
            original {
              src
            }
          }
        }
      }
    }
  `)

  const placeholderImage = data.placeholder.edges[0]?.node.gatsbyImageData

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {state.t4.blur ? (
            <div className={classes.content}>
              <GatsbyImage
                onClick={(e) => setState({ ...state, t4: { blur: false } })}
                className={classes.placeholder}
                image={placeholderImage}
                alt={t4Name}
              />
              <Typography variant="h4" className={classes.encounterHeadline}>
                T4 Fractal
              </Typography>
            </div>
          ) : (
            <div className={classes.content}>
              <GatsbyImage
                className={classes.image}
                image={t4Picture}
                alt={t4Name}
              />
              <Typography variant="h4" className={classes.encounterHeadline}>
                {t4Name}
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {state.cm.blur ? (
            <div className={classes.content}>
              <GatsbyImage
                onClick={(e) => setState({ ...state, cm: { blur: false } })}
                className={classes.placeholder}
                image={placeholderImage}
                alt={cmName}
              />
              <Typography variant="h4" className={classes.encounterHeadline}>
                CM Boss
              </Typography>
            </div>
          ) : (
            <div className={classes.content}>
              <GatsbyImage
                className={classes.image}
                image={cmPicture}
                alt={cmName}
              />
              <Typography variant="h4" className={classes.encounterHeadline}>
                {cmName}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default Encounters
